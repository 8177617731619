import React from 'react';
import './CareerOverview.css';

const CareerOverview = () => {
    return (
        <div className="career-overview-container">
            <div className="career-header">
                <h1 className="career-title highlight">Your Path to Tech Career Success</h1>
                <p className="career-subtitle highlight">Learnfinity's career guidance provides personalized support for your tech job search.</p>
            </div>
            <div className="career-body">
                {/* Section 1: CV Feedback */}
                <div className="career-side">
                    <img src={require('../../../assets/careerOverview/cv-feedback.png')} alt="CV Feedback" className="career-image-large" />
                    <div className="career-description highlight">
                        <h3>CV Feedback</h3>
                        <p>Our expert mentors will help optimize your CV, ensuring it stands out and aligns with industry standards.</p>
                    </div>
                </div>
                {/* Section 2: Portfolio Support */}
                <div className="career-side">
                    <img src={require('../../../assets/careerOverview/portfolio-support.png')} alt="Portfolio Support" className="career-image-large" />
                    <div className="career-description highlight">
                        <h3>Portfolio Support</h3>
                        <p>Showcase your skills with a professional portfolio. Get feedback to make it industry-ready.</p>
                    </div>
                </div>
                {/* Section 3: Mock Interviews */}
                <div className="career-side">
                    <img src={require('../../../assets/careerOverview/mock-interviews.png')} alt="Mock Interviews" className="career-image-large" />
                    <div className="career-description highlight">
                        <h3>Mock Interviews</h3>
                        <p>Prepare for interviews with mock sessions, covering both technical and behavioral questions.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CareerOverview;

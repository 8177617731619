import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './Landing.css';
import rocket from "../../assets/smallAssets/rocket.png";
import trustpilotStars from "../../assets/smallAssets/trustpilot.png";
import trustpilotLogo from "../../assets/smallAssets/trustpilot-logo.png";
const Landing = () => {
    return (
        <div className="landing-container">
            <div className="landing-content">
                <div className="trustpilot-reviews">
                    <span className="trustpilot-rating">Excellent</span>
                    <div className="trustpilot-stars">
                        <img src={trustpilotStars} alt="Trustpilot-Stars" />
                    </div>
                    <span className="trustpilot-score">4.8 out of 5 reviews</span>
                </div>
                <p className='tag-line'><span className='highlight'>Learnfinity:</span> Discover, Master, Excel.</p>
                <h1>Become a <span className='highlight'>Tech Expert</span> in Just A Few Months</h1>
                <p>Join Learnfinity for expert-led courses and personalized mentoring. From start to your dream job, we're with you all the way.</p>
                <HashLink to="/#courses" smooth>
                    <div className="btn" id="alternate-button">
                        APPLY TODAY 
                        <img className="rocket" src={rocket} alt="rocket"/>
                    </div>
                </HashLink>
            </div>
            <div className='LandingRightBig'>
                <div className="hero-image">
                    <img width="100%" className="background-image" src={require('../../assets/stockImages/coding1.png')} alt="Code1" />
                </div>
            </div>
            <div className="invisible-line"></div>
        </div>
    );
}

export default Landing;

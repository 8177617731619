import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AppContext } from '../../contexts/AppContext';
import rocket from "../../assets/smallAssets/rocket.png";
import './NavBar.css';

const NavBar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const { setShowPopup } = useContext(AppContext);
    const location = useLocation();

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
        if (!showNavbar) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    };

    const handleCloseNavbar = () => {
        setShowNavbar(false);
        document.body.classList.remove('no-scroll');
    };

    const isCoursePage = location.pathname.startsWith('/foundations') ||
        location.pathname.startsWith('/full-stack') ||
        location.pathname.startsWith('/advanced');

    const buttonText = isCoursePage ? "Enroll Now" : "View Courses";
    const cleanPathname = location.pathname.replace(/\/+$/, '');
    const buttonLink = buttonText === "Enroll Now" ? `${cleanPathname}#pricing` : "/#courses";

    const faqLink = isCoursePage ? `${cleanPathname}#FAQ` : '/#FAQ';

    return (
        <nav className="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 text-lg text-gray-700 bg-white navbar">
            <div className="logo">
                <NavLink to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: "none" }} onClick={handleCloseNavbar}>
                    <img className="logoImage" style={{ paddingRight: 10 }} width={'80px'} src={require('../../assets/logos/learnfinity-logo.png')} alt="learnfinity" />
                    <h1 className='logoText highlight'>Learnfinity</h1>
                </NavLink>
            </div>
            <div className={`menu-icon menu-btn ${showNavbar ? 'active' : ''}`} onClick={handleShowNavbar}>
                <span></span>
            </div>
            <div className={`nav-elements ${showNavbar ? 'active' : ''}`}>
                <ul className="pt-7 text-base text-gray-700 md:flex md:justify-between md:pt-0">
                    <li>
                        <HashLink className="md:px-1 py-2 block hover:text-blue-300" to="/events" onClick={handleCloseNavbar}>
                            Events
                        </HashLink>
                    </li>
                    <li>
                        <HashLink className="md:px-1 py-2 block hover:text-blue-300" to="/career-tips" onClick={handleCloseNavbar}>
                            Career Tips
                        </HashLink>
                    </li>
                    <li>
                        <HashLink className="md:px-1 py-2 block hover:text-blue-300" to="/grads" onClick={handleCloseNavbar}>
                            Graduate Career Support
                        </HashLink>
                    </li>
                    <li className="relative">
                        <HashLink className="md:px-1 py-2 block hover:text-blue-300" to="/articles" onClick={handleCloseNavbar}>
                            Articles
                        </HashLink>
                        <span className="absolute top-3 left-10 transform translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white text-small font-bold px-1 py-0.5 rounded-half highlight">
                            NEW
                        </span>
                    </li>
                    <li>
                        <HashLink className="md:px-1 py-2 block hover:text-blue-300" to={faqLink} onClick={handleCloseNavbar}>
                            FAQ
                        </HashLink>
                    </li>
                    <li className="btn md:px-4 py-2 block hover:text-purple-400" id="sign-up-button">
                        <HashLink id="sign-up-button-link" to={buttonLink} onClick={handleCloseNavbar}>
                            {buttonText} <img className='launch-pad' src={rocket} alt="Launchpad" />
                        </HashLink>
                    </li>
                </ul>
            </div>
            {showNavbar && <div className="overlay" onClick={handleCloseNavbar}></div>}
        </nav>
    );
}

export default NavBar;

import React, { useState } from 'react';
import CareerOverview from './CareerOverview/CareerOverview';

const CareerGuidancePage = () => {
  const [cvUploaded, setCvUploaded] = useState(false);

  const handleCvUpload = (e) => {
    e.preventDefault();
    // Assume AI review process is triggered here
    setCvUploaded(true);
  };

  return (
    <div className="#b0d9ff text-gray-800 px-6 py-12">
      {/* Header Section */}
      <CareerOverview />

      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between gap-6 p-8">
        {/* Data-Driven Insights Section */}
        <div className="md:w-1/2 w-full p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold mb-4 highlight">Tech Hiring Trends & Career Success</h2>
            <p className="text-lg mb-6">
            Stay ahead of the competition with insights into tech hiring trends. Did you know that 85% of our graduates secured roles within three months of completing the program?
            Data shows that roles in software development, cybersecurity, and IT support are in high demand with average salaries starting from £45,000.
            </p>
            <p className="text-lg mb-6">
            Explore the current hiring landscape and align your skills with the top opportunities in the tech industry.
            </p>
        </div>

        {/* Video Testimonials and How-To Section */}
        <div className="md:w-1/2 w-full p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold mb-4 highlight">Hear from Our Successful Graduates</h2>
            <p className="text-lg mb-6">
            Watch our recent graduates share their stories on how our program helped them land their dream jobs. Learn how to ace your interviews and build a portfolio that gets you noticed.
            </p>
            <div className="mb-6">
            <iframe width="100%" height="315" src={require("../../assets/videos/ansh-testimonial.mp4")} title="Testimonial Video" frameBorder="0" allowFullScreen></iframe>
            </div>
            <button className="btn-secondary mt-6">
            Watch More Success Stories
            </button>
        </div>
      </div>


      {/* Success Metrics Section */}
      <div className="max-w-4xl mx-auto p-8 rounded-lg shadow-md mb-10">
        <h2 className="text-3xl font-semibold mb-4 highlight">Our Results Speak for Themselves</h2>
        <p className="text-lg mb-6">
          Over 90% of our graduates have secured jobs within the first 6 months of completing our program. These roles span across top companies like Google, Amazon, and Microsoft.
        </p>
        <p className="text-lg mb-6">Join our network of successful tech professionals and start your career today!</p>
      </div>
    </div>
  );
};

export default CareerGuidancePage;

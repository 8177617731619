import React from 'react';
import './Policies.css';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => (
    <div className="policy-container">
        <Helmet>
            <title>Terms and Conditions - Learnfinity</title>
            <meta name="description" content="Learnfinity's Terms and Conditions outline the rules and regulations for the use of our website." />
            <meta property="og:title" content="Terms and Conditions - Learnfinity" />
            <meta property="og:description" content="Learnfinity's Terms and Conditions outline the rules and regulations for the use of our website." />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://www.learnfinity.co.uk/terms-and-conditions" />
            <meta property="og:image" content="https://www.learnfinity.co.uk/path/to/your-image.jpg" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content="Terms and Conditions - Learnfinity" />
            <meta property="twitter:description" content="Learnfinity's Terms and Conditions outline the rules and regulations for the use of our website." />
            <meta property="twitter:image" content="https://www.learnfinity.co.uk/images/lf.png" />
        </Helmet>
        <h1>Terms and Conditions</h1>
        <p>Last updated: 07/07/2023</p>

        <h2>Introduction</h2>
        <p>Welcome to Learnfinity!</p>
        <p>
            These terms and conditions outline the rules and regulations for the use of Learnfinity's Website, located at https://learnfinity.co.uk/.
        </p>
        <p>
            By accessing this website we assume you accept these terms and conditions. Do not continue to use Learnfinity if you do not agree to take all of the terms and conditions stated on this page.
        </p>

        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>

        <h3>Definitions</h3>
        <p>For the purposes of these Terms and Conditions:</p>
        <ul>
            <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Terms and Conditions) refers to Learnfinity.</li>
            <li><strong>Service</strong> refers to the Website.</li>
            <li><strong>You</strong> means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            <li><strong>Website</strong> refers to Learnfinity, accessible from https://learnfinity.co.uk/</li>
        </ul>

        <h2>Acknowledgment</h2>
        <p>
            These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
        </p>
        <p>
            Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
        </p>
        <p>
            By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
        </p>
        <p>
            You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
        </p>

        <h2>Accounts</h2>
        <p>
            When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.
        </p>
        <p>
            You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a third-party service.
        </p>
        <p>
            You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.
        </p>

        <h2>Intellectual Property</h2>
        <p>
            The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.
        </p>
        <p>
            The Service is protected by copyright, trademark, and other laws of both the country and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.
        </p>

        <h2>Links to Other Websites</h2>
        <p>
            Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.
        </p>
        <p>
            The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or services.
        </p>
        <p>
            We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services that You visit.
        </p>

        <h2>Termination</h2>
        <p>
            We may terminate or suspend Your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
        </p>
        <p>
            If You wish to terminate Your account, You may simply discontinue using the Service.
        </p>
        <p>
            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
        </p>

        <h2>Governing Law</h2>
        <p>
            These Terms shall be governed and construed in accordance with the laws of the country, without regard to its conflict of law provisions.
        </p>
        <p>
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.
        </p>

        <h2>Changes</h2>
        <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </p>
        <p>
            By continuing to access or use our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Service.
        </p>

        <h2>Contact Us</h2>
        <p>
            If you have any questions about these Terms, please contact us at:
            <br />Email: support@learnfinity.co.uk
        </p>
    </div>
);

export default TermsAndConditions;

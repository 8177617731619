import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import './TestimonialSlider.css'; 
import ReactPlayer from 'react-player';

const testimonials = [
  {
    name: 'Ansh',
    videoUrl: require('../../assets/videos/ansh-testimonial.mp4'),
    feedback: 'An amazing program that really helped me transition into tech!',
  },
  {
    name: 'Mubarak',
    videoUrl: require('../../assets/videos/mubarak-testimonial.mp4'),
    feedback: 'I loved the support and personalized guidance throughout my journey.',
  },
  {
    name: 'Khalid',
    videoUrl: require('../../assets/videos/khalid-testimonial.mp4'),
    feedback: 'Fantastic experience that prepared me well for the industry.',
  },
];

const TestimonialSlider = () => {
  return (
    <section className="testimonial-slider">
      <h2 className="testimonial-title">Client<span className='highlight'>Success</span>Stories!</h2>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        spaceBetween={30}
        slidesPerView={1}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className="testimonial-slide">
            <div className="testimonial-content">
              <ReactPlayer
                url={testimonial.videoUrl}
                controls
                className="testimonial-video"
              />
              <p className="testimonial-feedback">"{testimonial.feedback}"</p>
              <h3 className="testimonial-name">- {testimonial.name}</h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default TestimonialSlider;
